<template>
  <f-page :title="$t('labels.fileRequestSearch')">
    <f-form @submit="() => this.$refs.fileRequestDatatable.refresh()">
      <f-card>
        <f-row>
          <f-col md="3">
            <f-input
              v-model="search.requestNo"
              :label="$t('labels.requestNumber')"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.insurancePolicyNo"
              :label="$t('labels.policyNumberTitle')"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.callerFullname"
              :label="$t('labels.fileCallerFullname')"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.customerFullname"
              :label="$t('labels.claimFullname')"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.callerPhone"
              :label="$t('labels.phone')"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.no"
              :label="$t('labels.fileNo')"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.vehiclePlateNo"
              :label="$t('labels.plate')"
            />
          </f-col>
          <f-col md="3">
            <f-select
              v-model="search.vehicleBrandId"
              data-url="/select/vehicleBrands"
              :label="$t('labels.brand')"
            />
          </f-col>
          <f-col md="3">
            <f-select
              v-model="search.cityId"
              data-url="/select/cities"
              :label="$t('labels.selectCity')"
            />
          </f-col>
          <f-col md="3">
            <f-select
              v-model="search.status"
              :label="$t('labels.status')"
              :options="statuses"
              value-type="string"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.startDate"
              :label="$t('labels.registerDateRange')"
              type="date"
            />
          </f-col>
          <f-col md="3">
            <f-input
              v-model="search.endDate"
              inline
              type="date"
            />
          </f-col>
        </f-row>
        <template #footer>
          <f-button
            class="float-right"
            icon="search"
            :label="$t('labels.search')"
            type="submit"
            variant="primary"
          />
          <f-button
            class="float-right mr-2"
            icon="eraser"
            :label="$t('labels.reset')"
            variant="light"
            @click="() => (search = {})"
          />
          <f-row>
            <f-switch
              v-model="search.isFiled"
              ref="isActiveSwitch"
              :false-label="$t('labels.notFiled')"
              :label="$t('labels.filedOperations')"
              :show-checkbox="true"
              :true-label="$t('labels.filed')"
            />
          </f-row>
        </template>
      </f-card>
    </f-form>
    <f-card>
      <f-row>
        <f-col md="12">
          <f-datatable
            ref="fileRequestDatatable"
            :actions="actionData"
            :columns="fileRequestColumns"
            data-url="/companyApp/fileRequests"
            :features="{
              serverSidePagination: true,
              create: true,
              delete: false,
              update: true
            }"
            :query-params="search"
            @action="onClickActions"
            @create="openFileRequestCreateModal"
            @update="openFileRequestEditModal"
          />
        </f-col>
      </f-row>
    </f-card>
    <f-modal
      id="modalFileRequestEdit"
      ref="modalFileRequestEdit"
      :submit-disabled="disabledForStatus"
      :title="$t('labels.fileRequestEditAndView')"
      @submit="saveFileRequest"
    >
      <template slot="header-right">
        <div class="d-flex justify-space-end">
          <f-user-edit-info
            v-if="fileRequestData.createdByCompanyUser || fileRequestData.createdBy"
            class="pr-5"
            :custom-data="{
              dataBy: fileRequestData.assumedBy,
              dataAt: fileRequestData.assumedAt,
              icon: 'user',
              tooltip: 'Üstlenme',
            }"
            :data="userEditInfo"
          />
          <f-row v-if="fileRequestData.status === fileRequestStatuses.WAITING">
            <f-button
              class="mr-2"
              icon="trash-o"
              :label="$t('labels.delete')"
              pill
              type="submit"
              variant="dark"
              @click="changeStatusToDeleted"
            />
          </f-row>
        </div>
      </template>
      <template slot="footer-left">
        <f-button
          v-if="fileRequestData.status === fileRequestStatuses.ASSUMED && fileRequestData.file && fileRequestData.file.no"
          class="mr-auto"
          icon="share-square-o"
          :label="$t('labels.goToFile')"
          type="submit"
          variant="outline-secondary"
          @click="goToFile"
        />
      </template>
      <ModalFileRequestEdit
        :file-request="fileRequestData"
        :selected-file-request-data="selectedFileRequestData"
      />
    </f-modal>
  </f-page>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalFileRequestEdit from './ModalFileRequestEdit'
import { fileRequestColumns } from '@/data/columns'
import { fileRequestStatuses } from '@/data/enums'

export default {
  components: {
    ModalFileRequestEdit
  },
  data () {
    return {
      search: {
        status: fileRequestStatuses.WAITING
      },
      fileRequestData: {},
      selectedFileRequestData: {},
      fileRequestColumns,
      fileRequestStatuses,

      actionData: [
        {
          name: 'goToFile',
          icon: 'share-square-o',
          visibility: row => row.status === fileRequestStatuses.ASSUMED && row.file && row.file.no !== null
        }
      ],

      statuses: [
        {
          id: fileRequestStatuses.WAITING,
          name: `${this.$t('labels.waiting')}`
        },
        {
          id: fileRequestStatuses.ASSUMED,
          name: `${this.$t('labels.assumed')}`
        },
        {
          id: fileRequestStatuses.DELETED,
          name: `${this.$t('labels.deleted')}`
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['user']),
    disabledForStatus () {
      return (
        this.fileRequestData.status &&
        this.fileRequestData.status !== fileRequestStatuses.WAITING
      );
    },
    userEditInfo () {
      const { createdAt, createdBy, updatedAt, updatedBy } = this.fileRequestData;

      const userInfo = {
        createdAt,
        createdBy: createdBy || {
          fullname: this.fileRequestData.createdByCompanyUser.username
        },
        updatedAt,
        updatedBy: updatedBy || {
          fullname: this.fileRequestData.updatedByCompanyUser.username
        },
      };

      return userInfo;
    }
  },
  methods: {
    openFileRequestCreateModal () {
      this.fileRequestData = {}
      this.selectedFileRequestData = {}
      this.$refs.modalFileRequestEdit.show()
    },
    async saveFileRequest () {
      if (this.fileRequestData.id) {
        this.fileRequestData = await this.$http.put(
          `/companyApp/fileRequests/${this.fileRequestData.id}`,
          this.fileRequestData
        )
      } else {
        this.fileRequestData = await this.$http.post(
          '/companyApp/fileRequests',
          this.fileRequestData
        )
      }

      this.fileRequestData = {}
      this.$refs.modalFileRequestEdit.hide()
      this.$refs.fileRequestDatatable.refresh()
    },
    openFileRequestEditModal (row) {
      this.fileRequestData = row;
      this.selectedFileRequestData.brandId = row.vehicleModel?.vehicleBrandId ?? null;
      this.selectedFileRequestData.cityId = row.claimCounty?.cityId ?? null;
      this.$refs.modalFileRequestEdit.show();
    },
    async onClickActions (row, name) {
      if (name === 'goToFile') {
        this.goToFile(row)
      }
    },
    async changeStatusToDeleted () {
      const data = this.fileRequestData

      if (data.status !== fileRequestStatuses.DELETED) {
        const result = await this.$flex.modal.confirm(
          this.$t('messages.deleteFileRequestConfirmMessage'),
          {
            size: 'md',
            okTitle: this.$t('labels.delete'),
            cancelTitle: this.$t('labels.cancel'),
            okVariant: 'danger',
            cancelVariant: 'light'
          }
        )

        if (result) {
          this.fileRequestData = await this.$http.put(
            `/companyApp/fileRequests/${data.id}/changeStatusToDeleted`,
            data
          )
          this.$refs.modalFileRequestEdit.hide()
          this.$refs.fileRequestDatatable.refresh()
        }
      }
    },
    goToFile (row) {
      const data = row || this.fileRequestData

      if (data.file.no) {
        this.$router.push({
          name: 'files',
          params: { id: data.fileId }
        })
      }
    },
  }
}
</script>

<style scoped>
#userEditInfo {
  margin-top: 7px;
}
</style>
