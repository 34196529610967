<template>
  <div>
    <f-row>
      <f-col
        v-if="fileRequest.id"
        class="p-3 d-flex justify-content-between"
        md="12"
      >
        <div>
          <strong>{{ $t(`labels.requestNumber`) }} : &nbsp;</strong>
          {{ fileRequest.id }}
          <strong
            v-if="fileRequest.file && fileRequest.file.no && fileRequest.status === fileRequestStatuses.ASSUMED"
            class="ml-5"
          >{{ $t(`labels.fileNo`) }}: &nbsp;</strong>
          {{ fileRequest.file && fileRequest.file.no ? fileRequest.file.no : '' }}
          <strong class="ml-5">{{ $t(`labels.requestStatus`) }}: &nbsp;</strong>
          {{ $t(`labels.${fileRequest.status}`) }}
        </div>
      </f-col>
    </f-row>
    <f-row>
      <f-col md="6">
        <f-card
          :header="$t(`labels.callerInformations`)"
          no-shadow
        >
          <f-row>
            <f-col md="6">
              <f-select
                v-model="fileRequest.callerTypeId"
                :data-params="{ companyId: user.companyId }"
                data-url="/select/fileCallerTypes"
                :label="$t('labels.fileCallerType')"
              />
            </f-col>
            <f-col md="6">
              <f-input
                :label="$t('labels.phoneNumber')"
                phone
                :raw-value.sync="fileRequest.callerPhone"
                rules="required"
              />
            </f-col>
            <f-col md="6">
              <f-input
                v-model="fileRequest.callerFirstname"
                :label="$t('labels.firstname')"
              />
            </f-col>
            <f-col md="6">
              <f-input
                v-model="fileRequest.callerLastname"
                :label="$t('labels.lastName')"
              />
            </f-col>
          </f-row>
        </f-card>
      </f-col>
      <f-col md="6">
        <f-card
          :header="$t(`labels.customerInformation`)"
          no-shadow
        >
          <template slot="header-right">
            <f-button
              class="mr-2"
              icon="plus"
              :label="$t('labels.fileCallerType')"
              size="sm"
              variant="info"
              @click="setFileCallerToFileCustomer"
            />
          </template>
          <f-row>
            <f-col md="6">
              <f-input
                v-model="fileRequest.customerFirstname"
                :label="$t('labels.firstname')"
              />
            </f-col>
            <f-col md="6">
              <f-input
                v-model="fileRequest.customerLastname"
                :label="$t('labels.lastName')"
              />
            </f-col>
            <f-col md="12">
              <f-input
                :label="$t('labels.phoneNumber')"
                phone
                :raw-value.sync="fileRequest.customerPhone"
              />
            </f-col>
          </f-row>
        </f-card>
      </f-col>
      <f-card
        :header="$t(`labels.policyVehicleInformations`)"
        no-shadow
      >
        <template slot="header-right">
          <f-button
            class="float-left"
            icon="search"
            :label="$t(`labels.insurancePolicySearch`)"
            size="sm"
            variant="primary"
            @click="openInsurancePolicySearchModal"
          />
        </template>
        <f-row>
          <f-col md="6">
            <f-input
              v-model="fileRequest.insurancePolicyNo"
              :label="$t('labels.policyNumberTitle')"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="fileRequest.vehiclePlateNo"
              :label="$t('labels.plate')"
            />
          </f-col>
          <f-col md="8">
            <f-select-group
              child-data-url="/select/vehicleModels"
              :child-label="vehicleModelTitle"
              parent-data-url="/select/vehicleBrands"
              :parent-label="vehicleBrandTitle"
              parent-prop="brandId"
              :selected-child-id.sync="fileRequest.vehicleModelId"
              :selected-parent-id.sync="selectedFileRequestData.brandId"
            />
          </f-col>
          <f-col md="4">
            <f-input
              v-model="fileRequest.vehicleModelYear"
              :label="$t('labels.modelYear')"
              number
            />
          </f-col>
        </f-row>
      </f-card>

      <f-card
        :header="$t(`labels.incidentInformation`)"
        no-shadow
      >
        <f-select-group
          child-data-url="/select/counties"
          :child-label="$t('labels.selectCounty')"
          parent-data-url="/select/cities"
          :parent-label="$t('labels.selectCity')"
          parent-prop="cityId"
          :selected-child-id.sync="fileRequest.claimCountyId"
          :selected-parent-id.sync="selectedFileRequestData.cityId"
        />
      </f-card>

      <f-col>
        <f-input
          v-model="fileRequest.note"
          :label="$t('labels.note')"
          multiline
          rules="required"
        />
      </f-col>
    </f-row>
    <f-modal
      id="insurancePolicySearch"
      ref="modalInsurancePolicySearch"
      cancel-disabled
      lazy
      submit-disabled
      :title="$t(`labels.insurancePolicySearch`)"
    >
      <ModalInsurancePolicySearch
        @select="selectInsurancePolicy"
      />
    </f-modal>
  </div>
</template>

<script>
import { fileRequestStatuses } from '@/data/enums'
import ModalInsurancePolicySearch from './ModalInsurancePolicySearch.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalInsurancePolicySearch,
  },
  props: {
    fileRequest: {
      type: Object
    },
    selectedFileRequestData: {
      type: Object
    }
  },
  data () {
    return {
      fileRequestStatuses,
      vehicleBrandTitle: this.$t('labels.brand'),
      vehicleModelTitle: this.$t('labels.model'),
    }
  },
  computed: { ...mapGetters(['user']) },
  methods: {
    setFileCallerToFileCustomer () {
      this.$set(this.fileRequest, 'customerFirstname', this.fileRequest.callerFirstname)
      this.$set(this.fileRequest, 'customerLastname', this.fileRequest.callerLastname)
      this.$set(this.fileRequest, 'customerPhone', this.fileRequest.callerPhone)
    },
    openInsurancePolicySearchModal () {
      this.$refs.modalInsurancePolicySearch.show()
    },
    selectInsurancePolicy (selectedInsurancePolicy) {
      if (selectedInsurancePolicy.vehicleModelName || selectedInsurancePolicy.vehiclePlateNo) {
        this.fileRequest.vehiclePlateNo = selectedInsurancePolicy.vehiclePlateNo;
        this.fileRequest.vehicleModelYear = selectedInsurancePolicy.vehicleYear;

        if (selectedInsurancePolicy.vehicleModelId) {
          this.selectVehicleModel(selectedInsurancePolicy.vehicleModelId, selectedInsurancePolicy.vehicleModel?.vehicleBrandId);
        } else {
          this.createCustomVehicleModel(selectedInsurancePolicy.vehicleBrandId, selectedInsurancePolicy.vehicleModelName, selectedInsurancePolicy.vehicleBrandName);
        }
      } else {
        this.fileRequest.insurancePolicyNo = null
        this.fileRequest.vehiclePlateNo = null;
        this.fileRequest.vehicleModelYear = null;
        this.fileRequest.vehicleModelId = null;
        this.fileRequest.vehicleBrandName = null;
        this.fileRequest.vehicleModelName = null;
        this.selectedFileRequestData.brandId = null;
      }

      this.$set(this.fileRequest, 'insurancePolicyNo', selectedInsurancePolicy.no)

      this.vehicleBrandLabel();
      this.vehicleModelLabel();

      this.$refs.modalInsurancePolicySearch.hide();
    },
    selectVehicleModel (vehicleModelId, vehicleBrandId) {
      this.fileRequest.vehicleModelId = vehicleModelId;
      this.selectedFileRequestData.brandId = vehicleBrandId;
    },
    createCustomVehicleModel (vehicleBrandId, vehicleModelName, vehicleBrandName) {
      this.fileRequest.vehicleModelName = vehicleModelName;
      this.selectedFileRequestData.brandId = vehicleBrandId;
      this.fileRequest.vehicleModelId = null

      if (!vehicleBrandId) {
        this.fileRequest.vehicleBrandName = vehicleBrandName;
      }
    },
    vehicleBrandLabel () {
      this.vehicleBrandTitle = this.$t('labels.brand')

      if (this.fileRequest.vehicleBrandName && !this.selectedFileRequestData.brandId) {
        this.vehicleBrandTitle += ` [${this.$t('labels.notMatched')}: ${this.fileRequest.vehicleBrandName
        }]`
      }
    },
    vehicleModelLabel () {
      this.vehicleModelTitle = this.$t('labels.model')

      if (this.fileRequest.vehicleModelName && !this.fileRequest.vehicleModelId) {
        this.vehicleModelTitle += ` [${this.$t('labels.notMatched')}: ${this.fileRequest.vehicleModelName
        }]`
      }
    }
  }
}
</script>
