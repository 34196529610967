<template>
  <div>
    <f-form @submit="onClickSearchInsurancePolicy">
      <f-card no-shadow>
        <f-row>
          <f-col md="12">
            <f-input
              v-model="search.no"
              :label="$t('labels.policyNumber')"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.fullname"
              :label="$t('labels.insuredFullName')"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.taxNumber"
              :label="$t('labels.taxNo')"
              number
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.identificationNo"
              :label="$t('labels.identificationNo')"
              number
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.plateNo"
              :label="$t('labels.plate')"
            />
          </f-col>
        </f-row>
        <template #footer>
          <f-button
            class="float-right"
            icon="search"
            :label="$t('labels.search')"
            type="submit"
            variant="primary"
          />
        </template>
      </f-card>
    </f-form>
    <f-row>
      <f-col
        lg="12"
        md="12"
        sm="12"
      >
        <f-datatable
          ref="insurancePoliciesTable"
          :columns="insurancePolicyColumns"
          data-url="/companyApp/insurancePolicies"
          :features="{
            serverSidePagination: true,
            fullscreen: false,
            showColumns: false,
            singleSelect: true
          }"
          :options="{ rowStyle: insurancePolicyDatatableRowStyle }"
          :query-params="search"
          @select="selectInsurancePolicy"
        />
      </f-col>
    </f-row>
  </div>
</template>

<script>
import { insurancePolicyColumns } from '@/data/columns'

export default {
  data () {
    return {
      search: { isContractActive: true },
      searchResponseData: [],
      selectedInsuranceCategory: {},
      insurancePolicyColumns
    }
  },
  methods: {
    async onClickSearchInsurancePolicy () {
      this.$refs.insurancePoliciesTable.refresh()
      this.isShowTable = true
    },
    async selectInsurancePolicy (selectedInsurancePolicy) {
      const { contractId, no, startDate, contract } = selectedInsurancePolicy;

      if (!contract.isCleanCut && new Date(startDate) < new Date(contract.startDate)) {
        this.showRunoffWarning();
      } else if (selectedInsurancePolicy.isCancelled) {
        this.showCancellationAlert();
      } else {
        const insurancePolicy = await this.createInsurancePolicy(contractId, no, startDate);

        if (insurancePolicy) {
          this.$emit('select', insurancePolicy);
        }
      }
    },
    showRunoffWarning () {
      this.$flex.modal.alert(this.$t('messages.selectedInsurancePolicyRunoffWarning'), {
        size: 'md',
        okTitle: this.$t('labels.ok'),
        okVariant: 'danger',
        cancelVariant: 'light'
      });
    },

    showCancellationAlert () {
      this.$flex.modal.alert(this.$t('messages.selectedInsurancePolicyIsCancelled'), {
        size: 'md',
        okTitle: this.$t('labels.ok'),
        okVariant: 'danger',
        cancelVariant: 'light'
      });
    },

    async createInsurancePolicy (contractId, no, startDate) {
      const insurancePolicy = await this.$http.post('/companyApp/insurancePolicy', {
        contractId,
        no,
        startDate
      });

      return insurancePolicy;
    },
    insurancePolicyDatatableRowStyle (row, index) {
      if (row.isCancelled === true) {
        return { css: { 'background-color': 'red' } }
      }

      return {}
    }
  }
}
</script>
